export const audio = {
    music : {
        type: "mp3",
		name: "music",
		localurl: "",
        remoteurl: ""
    }
}

export const models = {
    rabbit : {
        type: "glb",
        name: "rabbit",
        localurl: "/assets/models/Rabbit_Dic_1st.glb",
        remoteurl: ""
    }
}

export const textures = {
    // face_normal : {
    //     type: "png",
	// 	name: "face normal texture",
	// 	localurl: "/assets/models/textures/Face_Normal.png",
    //     remoteurl: ""
    // }
}