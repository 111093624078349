export const CameraPermissionModule = () => {

	const onCameraStatusChange = ({ status }) => {

		console.log(status);
		if (status == 'requesting') {
			// myApplication.showCameraPermissionsPrompt()
			this.callbacks.requesting();
		} else if (status == 'hasStream') {
			// myApplication.dismissCameraPermissionsPrompt()
			this.callbacks.hasStream();
		} else if (status == 'hasVideo') {
			// myApplication.startMainApplictation()
			this.callbacks.hasVideo();
		} else if (status == 'failed') {
			// myApplication.promptUserToChangeBrowserSettings()
			this.callbacks.failed();
		}
	}

	return {
		name: 'eighth-wall-camera-startup',
		onCameraStatusChange,
	}

}