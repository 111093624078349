import * as THREE from 'three';
import { Vector3, Vector2 } from 'three';
import * as Helper from '../../Functions/Helper';
import { BaseGridParams } from '../../Utils/Constants';

export default class BaseGrid {

	constructor(gltf) {

		this.object = gltf.scene;

		this.actions = [];
		this.activeAction;
		this.previousAction;

		this.object.visible = false;

		this.scale = 1;

		this.pivotBone = this.object.children[0].children[0].children.find(bone => bone.name === 'DEF-head')
		this.tween = null;

		this.object.traverse(function (child) {
			child.frustumCulled = false;
			if (child.isMesh) {
				child.castShadow = true
			}
		})

		this.loadAnimations(gltf.animations);

	}

	// Loads in the animations from this model and sets up animation actions and a mixer
	loadAnimations = (animations) => {
		this.mixer = new THREE.AnimationMixer(this.object);
		for (let i = 0; i < animations.length; i++) {
			const clip = animations[i];
			const action = this.mixer.clipAction(clip);
			action.clampWhenFinished = true;
			action.setLoop(THREE.LoopOnce);
			this.actions.push(action);
		}
	}

	fadeToAction = (fromAction, toAction, duration) => {
		// If fromAction is playing, crossfade to the toAction
		if (!toAction.isRunning()) {
			toAction.crossFadeFrom(fromAction, duration, false);
			fromAction.stop();
		}
	
		// If toAction is not playing, play it
		if (!toAction.isRunning()) {
			toAction.play();
		}
	}

	// Animates and displays the base grid at the given position
	showModel = (pos = new Vector3(0, 0, 0)) => {
		if (this.object.visible == false) {
			const quat = new THREE.Quaternion();
			quat.setFromAxisAngle(new THREE.Vector3(0, 1, 0), - Math.PI / 3);

			Helper.setObjectProps(this.object, quat, this.scale, new Vector3(pos.x, pos.y + 0.1, pos.z));

			this.object.visible = true;

			// for (let i = 0; i < this.actions.length; i++) {
			// 	this.actions[i]
			// 		.reset()
			// 		.setEffectiveTimeScale(1)
			// 		.setEffectiveWeight(1)
			// 		.play();
			// }

			// this.actions[0].reset().setEffectiveTimeScale(1).play();
		}
	}

	runIntroAnimation = () => {
		this.actions[0].setEffectiveTimeScale(.6).play();
	}

	runLongAnimation = () => {
		this.actions[1].reset().setEffectiveTimeScale(1).crossFadeFrom(this.actions[0], 0.5, false).play();
	}

	runBeforeLookAnimation = () => {
		this.actions[2].reset().setEffectiveTimeScale(1).crossFadeFrom(this.actions[1], 0.5, false).play();
	}

	runAfterLookAnimation = () => {
		this.actions[3].reset().setEffectiveTimeScale(1).crossFadeFrom(this.actions[2], 0.5, false).play();
	}

	hideModel = () => {
		this.object.visible = false;
	}

	setScale = (scale) => {
		this.scale = scale
		this.object.scale.set(scale, scale, scale);
	}
}