import * as THREE from 'three';
import TWEEN, { Tween } from '@tweenjs/tween.js';
import Bowser from "bowser";

window.THREE = THREE;
const isIOS = Bowser.parse(window.navigator.userAgent).os.name === "iOS";

/**
 * Helper function to return appropriate Vector3 position depending on Android or iOS (Input position is default Android Position)
 * Only for Image Target it seems
 * 
 * @param { Vector3 } position 
 * @returns THREE.Vector3
 */
export const getOSPosition = ( position ) => { 

	let returnPos;
	if( isIOS ) {
		returnPos = new THREE.Vector3( position.x, position.z, ( position.y * -1 ) );
	}
	else {
		returnPos = new THREE.Vector3( position.x, position.y, position.z );
	}

	return returnPos;
};

/**
 * Helper function to set an Object's rotaiton, scale and position
 * @param {THREE.Object3D} object 
 * @param {THREE.Quaternion} rotation 
 * @param {Float} scale 
 * @param {Vector3} position 
 */
export const setObjectProps = ( object, rotation, scale, position ) => {
	
	object.scale.set(scale, scale, scale);
	object.quaternion.copy(rotation);
	object.position.copy(position);

};