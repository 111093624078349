import * as THREE from 'three'

export const CustomThreejsPipelineModule = () => {
	let scene3
	let engaged = false

	const xrScene = () => scene3;

	const engage = ({ canvas, canvasWidth, canvasHeight, GLctx }) => {
		if (engaged) {
			return
		}
		const scene = new THREE.Scene()
		const camera = new THREE.PerspectiveCamera(
			60.0, /* initial field of view; will get set based on device info later. */
			canvasWidth / canvasHeight,
			0.01,
			1000.0
		)
		scene.add(camera)

		// // add grid line helper
		// const gridHelper = new THREE.GridHelper(100, 100, 0xddccff, 0x808080);
		// scene.add(gridHelper);

		// //axis helper to debug
		// const axesHelper = new THREE.AxesHelper(5);
		// scene.add(axesHelper);

		const renderer = new THREE.WebGLRenderer({
			canvas,
			context: GLctx,
			alpha: false,
			antialias: true,
			logarithmicDepthBuffer: true,
		})
		renderer.autoClear = false
		renderer.setSize(canvasWidth, canvasHeight)
		renderer.shadowMap.enabled = true
		renderer.shadowMap.type = THREE.PCFSoftShadowMap
		renderer.toneMapping = THREE.LinearToneMapping;
		renderer.toneMappingExposure = Math.pow(0.9, 4.0);

		scene3 = { scene, camera, renderer }
		window.scene3 = scene3
		window.XR8.Threejs.xrScene = xrScene
		engaged = true
	}
	return {
		name: 'customthreejs',
		onStart: args => engage(args),
		onAttach: args => engage(args),
		onDetach: () => {
			engaged = false
		},
		onUpdate: ({ processCpuResult }) => {
			const realitySource = processCpuResult.reality || processCpuResult.facecontroller ||
				processCpuResult.layerscontroller

			if (!realitySource) {
				return
			}

			const { rotation, position, intrinsics } = realitySource
			const { camera } = scene3

			for (let i = 0; i < 16; i++) {
				camera.projectionMatrix.elements[i] = intrinsics[i]
			}

			// Fix for broken raycasting in r103 and higher. Related to:
			//   https://github.com/mrdoob/three.js/pull/15996
			// Note: camera.projectionMatrixInverse wasn't introduced until r96 so check before setting
			// the inverse
			if (camera.projectionMatrixInverse) {
				if (camera.projectionMatrixInverse.invert) {
					// THREE 123 preferred version
					camera.projectionMatrixInverse.copy(camera.projectionMatrix).invert()
				} else {
					// Backwards compatible version
					camera.projectionMatrixInverse.getInverse(camera.projectionMatrix)
				}
			}

			if (rotation) {
				camera.setRotationFromQuaternion(rotation)
			}
			if (position) {
				camera.position.set(position.x, position.y, position.z)
			}
		},
		onCanvasSizeChange: ({ canvasWidth, canvasHeight }) => {
			if (!engaged) {
				return
			}
			const { renderer } = scene3
			renderer.setSize(canvasWidth, canvasHeight)
		},
		onRender: () => {
			const { scene, renderer, camera } = scene3
			renderer.clearDepth()
			renderer.render(scene, camera)
		},
		// Get a handle to the xr scene, camera and renderer. Returns:
		// {
		//   scene: The Threejs scene.
		//   camera: The Threejs main camera.
		//   renderer: The Threejs renderer.
		// }
		xrScene: () => scene3,
	}
}
