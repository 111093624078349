import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';
import * as Loader from './Functions/Loader';
// import { CustomThreejsPipelineModule } from './CustomThreejsPipelineModule';
import { EventBus } from '@/vendor/Functions/EventBus';

export const ScenePipelineModule = (ThreePipeline) => {

	const clock = new THREE.Clock();
	let lastTime = 0;

	let raycaster = new THREE.Raycaster();
	let tapPosition = new THREE.Vector2();

	let surface;

	// this.audio = null;

	let ambLight;
	// let dirLight;
	let frontKeyLight;
	let backlight;
	let rightFillLight;

	let customCamera;

	// let baseGridModel;
	let rabbitModel;
	let scaleFactor = 6;

	let filesLoaded = false;

	let customThreejs = ThreePipeline;

	let assets;

	let isModelPlaced = false;
	let animationDone = false;
	let animationCounter = 0;

	//clocks
	let lookAtClock;

	let currentQuaternion = new THREE.Quaternion();

	let imageTargets = ['internal', 'IMG_6840', 'external_staging', 'production']

	// Populates some object into an XR scene and sets the initial camera position. The scene and
	// camera come from xr3js, and are only available in the camera loop lifecycle onStart() or later.
	const initXrScene = ({ scene, camera, renderer }) => {

		assets = Loader.loadAssets(scene, camera, renderer);

		// Set the initial camera position relative to the scene we just laid out. This must be at a
		// height greater than y=0.
		camera.position.set(0, 3, 0)
		customCamera = camera;
	}

	const showTarget = ({ detail }) => {
		if (!rabbitModel && !filesLoaded) return;

		if (imageTargets.includes(detail.name)) {
			const targetPos = { x: detail.position.x, y: 0, z: detail.position.z };

			// get y rotation in euler from quaternion rotation
			const targetRotation = detail.rotation
			const targetScale = detail.scale * scaleFactor;

			const quat = detail.rotation;
			let theta = Math.atan2(quat.y, quat.w);
			const newRot = new THREE.Quaternion(0, Math.sin(theta), 0, Math.cos(theta));

			rabbitModel.object.position.copy(targetPos);
			rabbitModel.object.quaternion.copy(newRot);
			rabbitModel.object.rotateY(-Math.PI / 4)
			rabbitModel.object.scale.set(targetScale, targetScale, targetScale)

			// translate to top left
			const moveDistance = { x: 0, y: 0, z: + 1.3 * detail.scale };
			rabbitModel.object.translateZ(moveDistance.z);

			// set plane
			const plane = assets.models.rabbitPlane
			plane.position.copy(rabbitModel.object.position);

			backlight.position.set(targetPos.x, 5 * targetScale, targetPos.z - 5 * targetScale);
			frontKeyLight.position.set(targetPos.x - 2 * targetScale, 2 * targetScale, targetPos.z - targetScale);
			rightFillLight.position.set(targetPos.x, 5 * targetScale, targetPos.z + 5 * targetScale);

			rabbitModel.runIntroAnimation();
			
			if (!rabbitModel.object.visible & !isModelPlaced) {
				rabbitModel.object.visible = true;
				EventBus.$emit('imageTargetFound', { detail });
			}
		}
	}

	function interpolateBoneRotation(deltaTime, lerpSpeed) {
		rabbitModel.pivotBone.lookAt(customCamera.position);
		rabbitModel.pivotBone.rotateX(Math.PI / 2)

		const targetQuaternion = rabbitModel.pivotBone.quaternion.clone();

		const targetEuler = new THREE.Euler().setFromQuaternion(targetQuaternion, 'XYZ');

		// Clamp rotation
		let minRotation = new THREE.Euler(-Math.PI / 3, -Math.PI / 4, -Math.PI / 3);
		let maxRotation = new THREE.Euler(Math.PI / 3, Math.PI / 4, Math.PI / 3);

		targetEuler.x = THREE.MathUtils.clamp(targetEuler.x, minRotation.x, maxRotation.x);
		targetEuler.y = THREE.MathUtils.clamp(targetEuler.y, minRotation.y, maxRotation.y);
		targetEuler.z = THREE.MathUtils.clamp(targetEuler.z, minRotation.z, maxRotation.z);

		// Convert back to quaternion
		targetQuaternion.setFromEuler(targetEuler);

		rabbitModel.pivotBone.quaternion.slerpQuaternions(currentQuaternion, targetQuaternion, deltaTime * lerpSpeed);

		// Update the currentQuaternion for the next frame
		currentQuaternion.copy(rabbitModel.pivotBone.quaternion);
	}

	// Grab a handle to the threejs scene and set the camera position on pipeline startup.
	const onStart = ({ canvas }) => {
		const { scene, camera, renderer } = customThreejs.xrScene() // Get the 3js scene from XR

		initXrScene({ scene, camera, renderer })  // Add content to the scene and set starting camera position.

		// prevent scroll/pinch gestures on canvas
		canvas.addEventListener('touchmove', (event) => {
			event.preventDefault()
		});

		// Sync the xr controller's 6DoF position and camera paremeters with our scene.
		XR8.XrController.updateCameraProjectionMatrix({
			origin: camera.position,
			facing: camera.quaternion,
		})
	}

	const onAttach = ({ canvas }) => {
		document.getElementById('cameraCanvasContainer').append(canvas);
	}

	// onUpdate is called once per camera loop prior to render. Any 3js geometry scene would
	// typically happen here.
	const onUpdate = (event) => {

		// Grabs the camera and effect composer from our custom ThreeJS AR Pipeline
		const { camera, composer } = customThreejs.xrScene();
		// Wait until all our assets are loaded
		if (Loader.checkModelLoadStatus() && !filesLoaded) {
			// Grabs all our loaded assets and assign them to local variables
			surface = assets.models.surface;

			ambLight = assets.lights.ambientLight;
			// dirLight = assets.lights.directionalLight;
			frontKeyLight = assets.lights.frontKeyLight;
			backlight = assets.lights.backlight;
			rightFillLight = assets.lights.rightFillLight;

			clock.start();

			if (assets.models.rabbit) {
				rabbitModel = assets.models.rabbit;
				rabbitModel.mixer.addEventListener('finished', (e) => {
					// intro [0] animation runs in showTarget function
					if (e.action._clip.name === 'intro') {
						rabbitModel.runLongAnimation();
					}

					if (e.action._clip.name === 'long') {
						currentQuaternion = rabbitModel.pivotBone.quaternion.clone();
						rabbitModel.runBeforeLookAnimation();
					}

					if (e.action._clip.name === 'looking_loop') {
						animationDone = true;
						lookAtClock = new THREE.Clock();

						// setting hardcoded animation time
						setTimeout(() => {
							rabbitModel.runAfterLookAnimation();
						}, 3000);
					}

					if (e.action._clip.name === 'out_head') {
						rabbitModel.actions[1].crossFadeFrom(rabbitModel.actions[3], 0.5, false);
						rabbitModel.runLongAnimation();
					}
				});
			}

			filesLoaded = true;
			XR8.XrController.recenter();
		}

		if (filesLoaded) {
			// Updates the animations for our models
			// tweenTimer += ( delta * 1000);
			TWEEN.update();
			const time = clock.getElapsedTime();
			let deltaTime = time - lastTime;
			if (rabbitModel && rabbitModel.object.visible) {
				if (lookAtClock && lookAtClock.getElapsedTime() > 3) {
					lookAtClock = null;
					// once animation is false, track is disabled
					animationDone = false;
					rabbitModel.pivotBone.quaternion.copy(currentQuaternion);
					rabbitModel.pivotBone.rotateX(-Math.PI / 2)
				}

				if (animationDone) {
					interpolateBoneRotation(deltaTime, 4);
				}
			}

			if (deltaTime > 1 / 60) {
				if (rabbitModel.mixer) rabbitModel.mixer.update(deltaTime);
				lastTime = time;
			}

		}
	}

	return {
		// Camera pipeline modules need a name. It can be whatever you want but must be
		// unique within your app.
		name: 'eighth-wall-template',
		onAttach,

		// onStart is called once when the camera feed begins. In this case, we need to wait for the
		// XR8.Threejs scene to be ready before we can access it to add content. It was created in
		// XR8.Threejs.pipelineModule()'s onStart method.
		onStart,

		onUpdate,

		// Listeners are called right after the processing stage that fired them. This guarantees that
		// updates can be applied at an appropriate synchronized point in the rendering cycle.
		listeners: [
			{ event: 'reality.imagefound', process: showTarget },
			{ event: 'reality.imageupdated', process: showTarget },
			// { event: 'reality.imagelost', process: hideTarget },
		],
	}
}