<template>
	<div class="ew-container" v-bind:id="cameraCanvasContainer">
		<canvas v-bind:id="cameraCanvas" />
		<Overlay v-if="!hasSeenImageTarget" />
	</div>
</template>

<script>

import MyEighthWall from '@/vendor/MyEighthWall';
import Overlay from '@/components/Overlay.vue';
import { EventBus } from '@/vendor/Functions/EventBus.js';

import { mapState } from 'vuex'

const STATE_CAMERA_ACCESS = "camera";
const STATE_AR = 'ar';

export default {
	components: {
		Overlay
	},
	name: 'EighthWall',
	props: [
		'state',
		'isIOS',
		'lang'
	],
	data: function() {
		return {
			isLoaded: false,
			cameraCanvas: 'camerafeed',
			cameraCanvasContainer: 'cameraCanvasContainer',
			hasSeenImageTarget: false,
			showVideo: false,
			fontHeight: 40,
			textContext: null,
			showNextButton: false,
			showCTAButton: false,
			currentStage: 0,
		}
	},
	computed: mapState( {
		buttonText() {
			return this.stageCTA[ this.currentStage ];
		},
		stateAR: function () {
			return this.state === STATE_AR;
		},
		locale: state => state.locale,
	}),
	created: function() {

		// XR Extras - provides utilities like load screen, almost there, and error handling.
		// 	See github.com/8thwall/web/xrextras
		const xr8xtra = "//cdn.8thwall.com/web/xrextras/xrextras.js";

		// 8thWall Web - Replace the app key here with your own app key
		const xr8 = "//apps.8thwall.com/xrweb?appKey=jJzWs26pMQJ6IEvfBpUt6KDfMqHHhws2qnFFbhbhcynuYwCMBalXy6qhMmSfU4lM3DI0Gg";

		// Load the script dependencies
		Promise.all( [
			this.$loadScript( xr8 ),
			this.$loadScript( xr8xtra )
		] ).then( ( values ) => {
			console.log( "Scripts Loaded!" );
			window.addEventListener('xrloaded', this.onXRLoaded)
		}).catch( ( error ) => {
			console.error( "Could not load script: ", error );
		});

		EventBus.$on('imageTargetFound', (data) => {
            // React to the image target being found
			this.hasSeenImageTarget = true
        });
	},

	beforeDestroy() {
        // Clean up
        EventBus.$off('imageTargetFound');
    },

	mounted: function() {

	},
	methods: {
		onXRLoaded: function() {
			this.isLoaded = true;
			// console.log( "Loaded XR8!", XR8 );

			this.init8Wall();
		},

		init8Wall: function() {

			// Init our 8th Wall manager class, and pass our callback functions to it.
			this.myEighthWall = new MyEighthWall( this.isIOS, this.cameraCanvas, this.lang, {
				requesting: this.requestingVideo,
				hasStream: this.hasStream,
				hasVideo: this.hasVideo,
				failed: this.requestFailed
			} );

			// XR8.initialize().then( () => this.myEighthWall.startXR8());
			this.myEighthWall.startXR8();
		},

		requestingVideo: function() {

			this.$emit( 'cameraStateChanged', 'requesting' );
		},

		hasStream: function() {

			this.$emit( 'cameraStateChanged', 'hasStream' );
		},

		hasVideo: function() {
			this.$emit( 'cameraStateChanged', 'hasVideo' );
		},

		requestFailed: function() {

			this.$emit( 'cameraStateChanged', 'failed' );
		},

		doContextParams() {
			this.textContext.font = `${ this.fontHeight }px sans serif`
			this.textContext.fillStyle = "#0D2F76"
			this.textContext.textBaseline = "middle"
		},

		startExperience () {
			this.myEighthWall.start();
			this.showNextButton = false;
		},

		goToPage () {
			window.open(this.$store.state.locale.page_link);
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.ar-overlay-container {
	width: 100%;
    height: 100%;
	border: none;

	position: absolute;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	pointer-events: none
}
.ew-container {
	position: fixed;
	top: 0;
    right: 0;
    bottom: 0;
    left: 0;

	z-index: 2;

	text-align: center;
}

.fade-enter-active, .fade-leave-active {
	transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}

</style>
