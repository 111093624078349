<template>
	<button class="continue-button" :class="{bg:background}" v-on:click="onButtonClicked"><slot/></button>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'ButtonComp',
	components: {  },
	props: {
		background: { type: Boolean, default:true }
	},
	data: function() {
		return {
		}
	},
	computed: mapState( {
		locale: state => state.locale,
	}),
	methods: {
		onButtonClicked() {
            this.$emit( 'onButtonClicked' )
        }
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.continue-button {
	// width: 250px;
    // height: 51px;

    // margin-top: 15px;

    // align-self: center;

    // font-family: 'Oswald';
    // font-style: normal;
    // font-weight: 400;
    // font-size: 15px;
    // letter-spacing: 0.1em;
    // color: #ffffff;

    // background: none;
    
    // border: solid 2px white;

	position: relative;

	padding: 16px 28px;

	width: 70%;
	height: 40px;

	border: 2px solid #FFFFFF;
	border-radius: 4px;

	background: none;
	background-repeat: no-repeat;
	background-image: url('/assets/images/TBStartButton.png');
	background-position: center;
	background-size: contain;

	pointer-events: auto;


    @media (min-width: 750px) { 
        width: 100%;
    }
}

.continue-button.bg {
	position: fixed;
    bottom: 50px;
    left: 50%;
    transform: translateX( -50% );

	background: #118CA5;
}

</style>