<template>
    <div class="landing-container">
        <transition name="fade">
            <div class="wrapper">
                Placeholder screen
                <ButtonComp @onButtonClicked="onContClicked" :background="false">
                    Click to continue
                </ButtonComp>
            </div>
        </transition>
    </div>
</template>

<script>

// import davidImage from '@/assets/images/sk-david.png';

import Bowser from "bowser";
import { mapState } from 'vuex'
import ButtonComp from './ButtonComp.vue';

export default {
    components: { ButtonComp },
    name: 'Landing',
    props: [
        'lang'
    ],
    data: function () {
        return {
        }
    },
    computed: mapState({
        isDesktop: function () {
            return (
                Bowser.parse(window.navigator.userAgent).platform.type === "desktop"
            );
        },
        locale: state => state.locale,
    }),
    methods: {
        onContClicked() {
            this.$emit('onContClicked')
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h1 {
    font-family: "Bruxism Bold", Helvetica, Arial, sans-serif;

    color: #FFFFFF;
}

h5 {
    font-family: "Bruxism Bold", Helvetica, Arial, sans-serif;

    color: #FFFFFF;
}

li {
    font-family: "Bruxism", Helvetica, Arial, sans-serif;

    color: #FFFFFF;
}

.landing-container {
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 100%;
}

.wrapper {
    z-index: 35;

    position: relative;

    background: #000000;

    display: flex;
    flex-direction: column;
    align-items: left;
    // justify-content: space-evenly;
    width: 100%;
    height: 100%;
}

.header-bar {
    background: #000000;
}

.header-bar .logo-img {
    position: relative;
    display: block;
    width: 30%;

    padding: 10px;
    margin: 0 auto;
    margin-bottom: 10px;
}

.content-container {
    height: 100%;
    width: 100%;
    padding-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;

    // justify-content: center;
}

.copy-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    width: 80%;
    max-width: 400px;

    padding: 40px 0;
    margin-top: 10%;

    border: solid 1px #FFFFFF;
    border-radius: 6px;

    position: relative;
}

.copy-container .corners {
    position: absolute;

    width: 13px;
    height: 13px;
}

.copy-container .corners.top-left {
    top: -1px;
    left: -1px;

    border-left: solid 4px #FFFFFF;
    border-top: solid 4px #FFFFFF;

    border-radius: 6px 0 0 0;
}

.copy-container .corners.top-right {
    top: -1px;
    right: -1px;

    border-right: solid 4px #FFFFFF;
    border-top: solid 4px #FFFFFF;

    border-radius: 0 6px 0 0;
}

.copy-container .corners.bottom-left {
    bottom: -1px;
    left: -1px;

    border-left: solid 4px #FFFFFF;
    border-bottom: solid 4px #FFFFFF;

    border-radius: 0 0 0 6px;
}

.copy-container .corners.bottom-right {
    bottom: -1px;
    right: -1px;

    border-right: solid 4px #FFFFFF;
    border-bottom: solid 4px #FFFFFF;

    border-radius: 0 0 6px 0;
}

.divider {
    margin: 20px 0px;

    height: 10px;
    width: 60%;

    // background: #ffffff;
    background: none;
    background-repeat: no-repeat;
    background-image: url('/assets/images/InstructionsDivider.png');
    background-position: center;
    background-size: contain;
}

.copy-container h1 {
    margin: 0;
}

.copy-container .instructions {
    width: 90%;
}

.copy-container .instructions ul {
    line-height: 18.7px;
    text-align: center;
    list-style: inside;

    padding-inline-start: 0;
    margin: 0;
}

.copy-container .instructions ul li {
    list-style-type: '+';
}

.button-container {
    width: 100%;
    text-align: center;
    padding-bottom: 2%;
}

.logo-img-desktop {

    position: absolute;
    bottom: 40px;

    display: block;
    width: 120px;

    margin: 0 40px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}
</style>