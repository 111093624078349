import * as THREE from 'three';
import { CustomThreejsPipelineModule } from './CustomThreejsPipelineModule';
import { CameraPermissionModule } from './CameraPermissionModule';
import { ScenePipelineModule } from './ScenePipelineModule';

window.THREE = THREE;

export default class MyEighthWall {

	constructor( iOS, cID, lang, callbacks ) {
		this.isIOS = iOS;
		this.canvasID = cID;
		this.lang = lang;
		this.callbacks = callbacks;
		this.currentStage = -1;

		this.CustomThreejs;

		this.isVisible = false;
		this.canStart = false;
	}

	restart = () => {
		this.currentStage = 0;
	}

	start = () => {
		this.currentStage = 0;
		this.canStart = true;
		// if( this.isIOS ) {
			// this.audio.play();
		// }
	}

	startXR8 = () => {

		this.CustomThreejs = CustomThreejsPipelineModule(); // Creates our own custom ThreeJS AR Scene
		this.CameraPermission = CameraPermissionModule(); // Camera permission module to ask for access
		this.Scene = ScenePipelineModule( this.CustomThreejs ); // Our custom pipeline above
		

		// If your app only interacts with image targets and not the world, disabling world tracking can
		// improve speed.
		XR8.XrController.configure({ disableWorldTracking: false })
		XR8.addCameraPipelineModules([  // Add camera pipeline modules.
			// Existing pipeline modules.
			XR8.GlTextureRenderer.pipelineModule(),      // Draws the camera feed.
			XR8.XrController.pipelineModule(),           // Enables SLAM tracking.
			this.CustomThreejs,							// Our custom ThreeJS AR Scene pipeline
			// XR8.Threejs.pipelineModule(),                // Creates a ThreeJS AR Scene. DEFAULT ThreeJS Pipeline
			XRExtras.AlmostThere.pipelineModule(),       // Detects unsupported browsers and gives hints.
			XRExtras.FullWindowCanvas.pipelineModule(),  // Modifies the canvas to fill the window.
			// XRExtras.Loading.pipelineModule(),           // Manages the loading screen on startup.
			XRExtras.RuntimeError.pipelineModule(),      // Shows an error image on runtime error.
			// Custom pipeline modules.
			this.CameraPermission,
			this.Scene,
		])

		let sessionConfig = { defaultEnvironment: { disabled: true } };
	
		// Open the camera and start running the camera run loop.
		XR8.run({ 
			canvas: document.getElementById( this.canvasID ), 
			allowedDevices: XR8.XrConfig.device().MOBILE, 
			sessionConfiguration: sessionConfig 
		})
	}
}
