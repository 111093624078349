<template>
    <div class="camera-access-container">
        <div class="header-bar">

        </div>
        <span class="camera-access-failed" v-if="!camAccess">
			<p>Camera access denied. Please check your browser's setting to ensure camera permission is enabled.</p>
		</span>
        <div class="instructions">
            <!-- <img class="logo-instructions" src="/assets/images/ar-icon.png" />
            <p >{{locale.camera_instructions}}</p> -->
            <!-- <img class="poweredby-img" src="//cdn.8thwall.com/web/img/almostthere/v2/poweredby-horiz-white-2.svg"/> -->
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
    components: {  },
    name: 'CameraAccess',
    props: {
        camAccess: { type: Boolean, default:true }
    },
	data: function() {
		return {
		}
	},
    computed: mapState( {
		locale: state => state.locale,
	}),
    methods: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.camera-access-container {
    height: 100%;

    background: #000000;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    
}

.camera-access-container .header-bar {
    width: 100%;
    background: #000000;
}

.camera-access-container .header-bar .logo-img {
    position: relative;
    display: block;
	width: 100px;

    padding: 10px;
    margin: 0 auto;
    margin-bottom: 10px;
}

.camera-access-container .instructions {
    position: fixed;
    bottom: 45px;
    left: 50%;
    width: 80%;
    transform: translateX(-50%);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.camera-access-failed {
    position: fixed;
    top: 40%;
    width: 80%;
    text-align: center;
}

p {
	font-family: "Bruxism", Helvetica, Arial, sans-serif;
	font-size: 14px;
	color: #FFFFFF;
}

.camera-access-container .instructions p {
    font-family: "Bruxism", Helvetica, Arial, sans-serif;
	font-size: 15px;
	color: #FFFFFF;
    line-height: 17.25px;

    margin: 10px 0 50px;
    text-align: center;
}

.poweredby-img {
    width: 35vw;
    max-width: 125px;
}
</style>