import * as THREE from 'three';
import Bowser from "bowser";
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

import * as assetPaths from '../AssetsConfig'; // Paths for all the assets to be loaded in
import Rabbit from '../Classes/Environment/Rabbit';

window.THREE = THREE;
const isIOS = Bowser.parse(window.navigator.userAgent).os.name === "iOS";

let assets = {};
let filesLoaded = false;

const textureLoader = new THREE.TextureLoader();
var manager = new THREE.LoadingManager();
const fbxLoader = new FBXLoader(manager);
let gltfLoader = new GLTFLoader(manager);

// let baseGridAsset;
let rabbitAsset;

const useLocal = true;
if (useLocal) {
	// 3D MODELS
	// baseGridAsset = assetPaths.models.baseGrid.localurl;
	rabbitAsset = assetPaths.models.rabbit.localurl;

	// AUDIOS
	// audioAsset = assetPaths.audio.music.localurl;
}

export const loadAssets = (scene, camera, renderer) => {

	gltfLoader.setCrossOrigin('anonymous')
	// .setDRACOLoader( DRACO_LOADER )
	// .setKTX2Loader( KTX2_LOADER.detectSupport( renderer ) )
	// .setMeshoptDecoder( MeshoptDecoder );

	assets.audio = loadAudio();
	assets.lights = loadLights(scene);
	assets.models = loadModels(scene);

	return assets;
};

const loadAudio = () => {
	let returnAudio = {};

	// let audio = new Audio(audioAsset);
	// audio.crossOrigin = 'anonymous';
	// audio.loop = false;

	// returnAudio.audio = audio;
	return returnAudio;
}

const loadLights = (scene) => {
	let returnLights = {};

	let ambientLight = new THREE.AmbientLight(0xe0e0ff, 1.5);
	scene.add(ambientLight);

	const backlight = new THREE.DirectionalLight(0xdedeff, 1.1,)
	backlight.castShadow = false 

	const frontKeyLight = new THREE.DirectionalLight(0xeeeeff, 2)
	frontKeyLight.position.set(0, 100, 100)
	frontKeyLight.castShadow = true

	const rightFillLight = new THREE.DirectionalLight(0xdedeff, 2.7)
	rightFillLight.position.set(100, 100, 100)
	rightFillLight.castShadow = false

	// light helper to scene
	// const backlightHelper = new THREE.DirectionalLightHelper(rightFillLight, 5, 0xff00ff);
	// scene.add(backlightHelper);

	// const frontKeyLightHelper = new THREE.DirectionalLightHelper(frontKeyLight, 5, 0xff0000);
	// scene.add(frontKeyLightHelper);

	scene.add(frontKeyLight, rightFillLight, backlight);

	returnLights.ambientLight = ambientLight;
	returnLights.backlight = backlight;
	returnLights.frontKeyLight = frontKeyLight;
	returnLights.rightFillLight = rightFillLight;

	return returnLights;
}

const loadModels = (scene) => {
	let returnModels = {};

	// LOAD IN 3D ASSETS
	gltfLoader.manager.onLoad = loaderManagerOnLoad;

	// Create a big plane
	var geometry = new THREE.PlaneGeometry(250, 250, 1, 1);   // THREE.PlaneGeometry (width, height, widthSegments, heightSegments)
	var material = new THREE.MeshBasicMaterial({ color: 0xffff00, transparent: true, opacity: 0.0, side: THREE.FrontSide });
	var plane = new THREE.Mesh(geometry, material);

	// Rotate 90 degrees (in radians) along X so plane is parallel to ground 
	plane.rotateX(1.5708)
	plane.position.set(0, 0, 0)
	returnModels.surface = plane;
	scene.add(plane);

	gltfLoader.load(rabbitAsset, function (gltf) {
		let rabbit = new Rabbit(gltf);
		returnModels.rabbit = rabbit;
		
		// // axis helper
		// const rabbitAxisHelper = new THREE.AxesHelper(5);
		// rabbit.object.add(rabbitAxisHelper);

		scene.add(rabbit.object);
	});

	const rabbitPlaneGeometry = new THREE.PlaneGeometry(250, 250, 1, 1);   // THREE.PlaneGeometry (width, height, widthSegments, heightSegments)
	const rabbitPlaneMaterial = new THREE.ShadowMaterial({ opacity: 0.5, side: THREE.DoubleSide});
	rabbitPlaneMaterial.opacity = 0.5;
	rabbitPlaneMaterial.receiveShadow = true;
	const rabbitPlane = new THREE.Mesh(rabbitPlaneGeometry, rabbitPlaneMaterial);

	// Rotate 90 degrees (in radians) along X so plane is parallel to ground
	rabbitPlane.rotateX(-1.5708)
	rabbitPlane.position.set(0, 0, 0);
	// receive shadow
	rabbitPlane.receiveShadow = true;
	// axis helper for plane
	// const rabbitPlaneAxisHelper = new THREE.AxesHelper(5);
	// rabbitPlane.add(rabbitPlaneAxisHelper);

	returnModels.rabbitPlane = rabbitPlane;
	scene.add(rabbitPlane);

	return returnModels;
}


export const checkModelLoadStatus = () => {
	return filesLoaded;
}


const loaderManagerOnLoad = () => {
	filesLoaded = true;
}

const loaderManagerProgress = (url, itemsLoaded, itemsTotal) => {
	console.log('Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.');
}

const loaderManagerStart = (url, itemsLoaded, itemsTotal) => {
	console.log('Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.');
}