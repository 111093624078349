<template>
	<div class="desktop-container">
		<span ref="message-container">
			<p>Please view experience on a mobile device.</p>
		</span>
	</div>
</template>

<script>
// import DesktopSidebar from './DesktopSidebar.vue'

export default {
	name: 'Desktop',
	components: {  },
	props: [
	],
	data: function() {
		return {
		}
	},
	computed: {
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.desktop-container {
	z-index: 30;

	position: fixed;

	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	font-size: 20px;

	background: #000000;

	// background-image: url('/assets/images/TB_Desktop.png');
	// background-size: contain;
	// background-position: center center;
	// background-repeat: no-repeat;
}

.desktop-container .message-container {
	display: flex;
	flex-direction: column;

	align-items: center;
	justify-content: center;
}

p {
	font-family: "Bruxism Bold", Helvetica, Arial, sans-serif;

    color: #FFFFFF;

	text-align: center;
}

</style>
